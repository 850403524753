// action types
import { SET_TICKETS_SUCCESS } from "../constants/action-types";

const setTickets = (tickets) => {
  return {
    type: SET_TICKETS_SUCCESS,
    payload: {
      data: tickets,
    },
  };
};

export { setTickets };
