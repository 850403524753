import React from 'react'

export class RedirectModal extends React.Component {
  render() {
    const { browser, onClickOk, message } = this.props

    const isMobile = browser && !browser.greaterThan.mobile
    const isTablet = browser && !isMobile && !browser.greaterThan.large

    return (
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">{message ? message : `Your cart has expired. Please click on "OK" to return to the ticket selection page.`}</div>
        </div>
        <div
          className="form_button-container"
          style={{ display: 'flex', justifyContent: 'flex-end', background: 'white' }}
        >
          <button
            type="button"
            className={`btn configure-button  small-text ${
              isMobile || isTablet ? 'auto-size' : 'half m-l-10 with-border'
            }`}
            style={{ width: 70, margin: 0 }}
            onClick={onClickOk}
          >
            {'OK'}
          </button>
        </div>
      </div>
    )
  }
}
