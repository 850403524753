import React from "react";
import { connect } from "react-redux";
import { setTickets } from "@actions/tickets";
import { setCheckoutData } from "@actions/checkout";
import { AddonsContainter } from "tf-checkout-react";
import { RedirectModal } from "@components/global/timer/RedirectModal";
import { createModal, removeModal } from "@actions/ui";
import { navigate } from "gatsby";

const AddOnsPage = (props) => {
  const { setCheckoutData, browser, createModal, removeModal } = props;
  const params = new URLSearchParams(window.location.search);
  const slug = params.get("slug")

  return (
    <div className="add_ons_page">
      <AddonsContainter
        enableTimer={true}
        onConfirmSelectionSuccess={({
          skip_billing_page,
          names_required,
          age_required,
          event_id,
          hash,
          total,
        }) => {
          if (skip_billing_page) {
            setCheckoutData({ hash, total });
            navigate(`/checkout/${hash}?slug=${slug}`);
          } else {
            navigate(
              `/billing-info?age_required=${age_required}&names_required=${names_required}&event_id=${event_id}&slug=${slug}`
            );
          }
        }}
        onCountdownFinish={() => {
          const modal = {
            id: "error-modal",
            Component: () => (
              <RedirectModal
                browser={browser}
                onClickOk={() => {
                  removeModal("error-modal")
                  navigate(`/events/${slug}`)
                }}
              />
            ),
            shouldCloseOnOverlayClick: false,
            isCloseBtn: false,
          };
          createModal(modal);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    browser: state.browser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTickets: (data) => {
      dispatch(setTickets(data));
    },
    setCheckoutData: (data) => {
      dispatch(setCheckoutData(data));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
  };
};

const AddonsRedux = connect(mapStateToProps, mapDispatchToProps)(AddOnsPage);

export default AddonsRedux;
